import React, { useEffect, useState } from "react";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { ClassScheduleDetails } from "./ClassScheduleDetails";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import Sidebar from "../../comman_page/Sidebar";
import { useLocation } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import { ClassSchedulePreview } from "./ClassSchedulePreview";

export const ClassSchedule = () => {
  const location = useLocation();
  const [loder,setLoder] = useState(false);

  const [preinfodata,setPreinfodata] = useState();
  useEffect(() => {
    if (location.state) {
      localStorage.setItem('preInfo', JSON.stringify(location.state));
      setPreinfodata(location.state);
    }else{
      setPreinfodata(JSON.parse(localStorage.getItem('preInfo')));
    }
  }, [location.state]);

  const onLoderChange = (data)=>{
    setLoder(data)
  }

  return (
    <>
    {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> </Spinner> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <ClassSchedulePreview setLoder={onLoderChange}  preinfodata={preinfodata} />
                  <Footer />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { CalenderScheduler } from "../../../../../sharedComponents/CalenderScheduler";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { BsSearch } from "react-icons/bs";
import { GrRefresh } from "react-icons/gr";
import { AiFillPlusCircle } from "react-icons/ai";
import { InstructorList, class_list, get_class_schedule_calendar, get_room_location } from "../../../../../Studio-Services/Studio_Services";
import moment from "moment";
import { CalenderShedulsClass } from "./CalenderShedulsClass";

export const Class = () => {
  const [listClass, setListClass] = useState([]);
  const [classOption, setClassOption] = useState([]);
  const [instructorOPT, setInstructorOPT] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [loder, setLoder] = useState(false);

  // const [calendardates, setCalendardates] = useState({ 'start': moment().startOf('year').format('YYYY-MM-DD'), 'end': moment().endOf('year').format('YYYY-MM-DD') });
  const [calendardates, setCalendardates] = useState({ 'start': "", 'end': "" });

  const [finalClass, setFinalClass] = useState();
  const [finalInstructor, setFinalInstructor] = useState();
  const [finalLocation, setFinalLocation] = useState();

  const [visibleRange, setVisibleRange] = useState(null);


  const getRoomLocation = async () => {
    const fData = {
      "business_id": localStorage.getItem("business_id")
    }
    const resp = await get_room_location(fData);
    if (resp) {
      const loc = [];
      (resp.data.data).map((e) => (
        loc.push({
          value: e.location_id, label: e.location_name
        })
      ))
      setLocationOption(loc);
    }
  }

  const InstructList = async () => {
    const fData = {
      "pageid": 1
    }
    const resp = await InstructorList(fData);
    if (resp) {
      const instOp = [];
      (resp.data.data).forEach(e => {
        if (e.status === "Approve") {
          instOp.push({
            value: e.instructor_id, label: `${e.name} ${e.lastname}`
          })
        }
      })
      setInstructorOPT(instOp);
    }
  }

  const classList = async () => {
    const fdata = {
      "pageid": "1",
      "business_id": localStorage.getItem("business_id")
    }
    const resp = await class_list(fdata);
    if (resp) {
      const clsOp = [];
      (resp.data.data).forEach(e => {
        if (e.status === "Active") {
          clsOp.push({
            value: e.class_id, label: e.class_name
          })
        }
      });
      setClassOption(clsOp)
    }
  }

  const calanderOnClhange = async (dateData) => {
    console.log('coal--1',dateData)
    const chDates = { ...dateData };
    const startOfMonth = chDates.start;
    const endOfMonth = chDates.end;
    setLoder(true);
    const passData = {
      'start': startOfMonth,
      'end': endOfMonth,
      'page': 1,
    };

    const resp = await get_class_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setListClass(resp.data);
    }
    setLoder(false);

  }

  useEffect(() => {
    if (calendardates && calendardates.start && calendardates.end) {
      calanderOnClhange(calendardates);
      return;
    }
    return;

  }, [calendardates])

  const classes = async () => {
    const startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('year').format('YYYY-MM-DD');
    setLoder(true);
    const passData = {
      start: startOfMonth,
      end: endOfMonth,
      page: 1,
    };

    const resp = await get_class_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setListClass(resp.data);
    }
    setLoder(false);
  };

  useEffect(() => {
    // classes();
    classList();
    InstructList();
    getRoomLocation();
  }, []);

  const setEventHandler = () => {
    setFinalClass();
    setFinalInstructor();
    setFinalLocation();
    classes();
  }

  const setCalendarHandler = async () => {
    // const currentDate = moment();
    // let startDate = currentDate.clone().startOf('month');
    // let endDate = currentDate.clone().endOf('month');

    let startOfMonthss = moment(visibleRange.start).format('YYYY-MM-DD');
    let endOfMonthss = moment(visibleRange.end).format('YYYY-MM-DD');
    // const startOfMonthss = currentDate.clone().startOf('month').format('YYYY-MM-DD');
    // const endOfMonthss = currentDate.clone().endOf('month').format('YYYY-MM-DD');

    let fData = '';
    if (finalClass && finalInstructor && finalLocation) {
      fData = {
        start: startOfMonthss,
        end: endOfMonthss,
        "page": 1,
        "class": [finalClass],
        "instructor": [finalInstructor],
        "location": [finalLocation]
      }
    } else if (finalClass && finalInstructor) {
      fData = {
        start: startOfMonthss,
        end: endOfMonthss,
        "page": 1,
        "class": [finalClass],
        "instructor": [finalInstructor],
      }
    } else if (finalClass && finalLocation) {
      fData = {
        start: startOfMonthss,
        end: endOfMonthss,
        "page": 1,
        "class": [finalClass],
        "location": [finalLocation]
      }
    } else if (finalInstructor && finalLocation) {
      fData = {
        start: startOfMonthss,
        end: endOfMonthss,
        "page": 1,
        "instructor": [finalInstructor],
        "location": [finalLocation]
      }
    } else if (finalClass) {
      fData = {
        start: startOfMonthss,
        end: endOfMonthss,
        "page": 1,
        "class": [finalClass],
      }
    } else if (finalInstructor) {
      fData = {
        start: startOfMonthss,
        end: endOfMonthss,
        "page": 1,
        "instructor": [finalInstructor],
      }
    } else if (finalLocation) {
      fData = {
        start: startOfMonthss,
        end: endOfMonthss,
        "page": 1,
        "location": [finalLocation]
      }
    }
    const resp = await get_class_schedule_calendar(fData);

    if (resp) {
      setListClass(resp.data);
    } else {
      setListClass([]);
    }
  }
  return (
    <>
      {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <div className="ListYourService">
                    <Container>
                      <Row className="pt-0 pb-0">
                        <Col>
                          <h4>SCHEDULED CLASSES</h4>
                        </Col>
                        <Col>
                          <SharedSelect
                            onChange={(e) => setFinalClass(e.target.value)}
                            options={classOption}
                            value={finalClass}
                          />
                        </Col>
                        <Col>
                          <SharedSelect
                            onChange={(e) => setFinalInstructor(e.target.value)}
                            options={instructorOPT}
                            value={finalInstructor}
                          />
                        </Col>
                        <Col>
                          <SharedSelect
                            onChange={(e) => setFinalLocation(e.target.value)}
                            options={locationOption}
                            value={finalLocation}
                          />
                        </Col>
                        <Col>
                          <div className="appointmentPlus">
                            <div className="appointIcons">
                              <SharedButton
                                onClick={setCalendarHandler}
                                variant={"outline"}
                                className={"AppointmentIcons"}
                                iconNode={<BsSearch />}
                              />
                              <SharedButton
                                variant={"outline"}
                                onClick={setEventHandler}
                                className={"AppointmentIcons"}
                                iconNode={<GrRefresh />}
                              />
                            </div>
                            <div className="appointIcons">
                              <SharedButton
                                variant={"danger"}
                                className={"PlusIcon"}
                                href={"/add-schedule-class"}
                                iconNode={<AiFillPlusCircle />}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CalenderShedulsClass resp={listClass} setVisibleRange={setVisibleRange} setCalendardates={setCalendardates} calendardates={calendardates} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>

                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

import React from "react";
import {  Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";

export const DataTable = ({ data, columns }) => {

  return (
    <>
      <Row >
        <Col>
          <BootstrapTable
            keyField="id"
            data={data}
            columns={columns}
            striped
            hover
            wrapperClasses="table-responsive"
            pagination={paginationFactory({ sizePerPage: 20 })}
          />
        </Col>
        {data && data.length <= 0 && (
          <p className="text-center">No Data Available</p>
        )}
      </Row>
     
    
    </>
  );
};


import http from "../http";
import Swal from "sweetalert2";
import logo from "../Images/logo_white_web.png"
import moment from "moment";
import { errorAlert } from "../Static_Services/Alertmsg";

let monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const login = async (data) => {
  try {
    const responce = await http.post("web/studio/login", data);
    if (responce) {
      if (responce.data.status == 1) {
        if (responce.data.data.redirect_to_verify == 1 || responce.data.data.redirect_to_verify == 2 || responce.data.data.redirect_to_verify == 3) {
          return responce;
        } else {
          localStorage.setItem('st_userData', JSON.stringify(responce.data.data));
          localStorage.setItem('Authorization', responce.data.data.Authorization);
          localStorage.setItem('id', responce.data.data.id);
          localStorage.setItem('business_id', responce.data.data.business_id);
          localStorage.setItem('marchant_id', responce.data.data.marchant_id);
          localStorage.setItem('cl_token_expire', responce.data.data.cl_token_expire);
          localStorage.setItem('business_token', responce.data.data.business_token);
          localStorage.setItem('list_token', responce.data.data.list_token);
          localStorage.setItem('month_token', responce.data.data.month_token);
          return responce;
        }
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }

    }
  } catch (error) {
    alert(error);
  }
}



export const webinfovalidation = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/webvalidation", data);
    if (responce) {
      return responce.data;
    }
  } catch (error) {
    alert(error);
  }
}

export const getProfile = async () => {
  try {
    const responce = await http.get("web/studio/get_business_profile");
    if (responce) {
      if (responce.data.status == 1) {
        localStorage.setItem('logo', responce.data.data.logo);
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const getPlanList = async () => {
  try {
    const responce = await http.get("web/transaction/plans_list");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
      else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const getServiceTypeList = async () => {
  try {
    const responce = await http.get("web/studio/get_services_type");
    if (responce) {
      if (responce.data.status == 1) {
        localStorage.setItem('logo', responce.data.data.logo);
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const get_skills = async () => {
  try {
    const responce = await http.get("web/studio/get_skills");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const generateToken = async (data) => {
  try {
    const responce = await http.post("webservices/api/get_clover_payment_token", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const class_customer = async (data) => {
  try {
    const responce = await http.post('web/report/class_customer_new', data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const cancel_class_list = async () => {
  try {
    const responce = await http.get("web/studio/cancel_class_list");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const customer_card_save_API = async (data) => {
  try {
    const responce = await http.post('web/studio/customer_card_save_new', data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}




export const StudioRegister = async (data) => {
  try {
    const responce = await http.post("web/studio/register", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const getBusinessMultiCatList = async (data) => {
  try {
    const responce = await http.post("web/studio/get_business_multiple_categories", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const businessProfile = async (data) => {
  try {
    const responce = await http.post("web/studio/register_business_profile", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const purchasePlan_New = async (data) => {
  try {
    const responce = await http.post("web/transaction_new/subcription_plan_purchase_new", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const purchasePlan = async (data) => {
  try {
    const responce = await http.post("web/transaction_new/subcription_plan_purchase", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const buy_now_services_cash = async (data) => {
  try {
    const responce = await http.post("web/studio/buy_now_services_cash", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_class_image = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/add_class_image", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_workshop_image = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/add_workshop_image", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const add_testimonial_image = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/add_testimonial_image", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const add_service_image = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/add_service_image", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const workshop_list = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/workshop_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const update_business_profile = async (data) => {
  try {
    const responce = await http.post("web/studio/update_business_profile", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const clover_buy_now_workshop = async (data) => {
  try {
    const responce = await http.post("web/studio/clover_buy_now_workshop", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const class_customer_detail = async (data) => {
  try {
    const responce = await http.post("web/report/class_customer_detail_new", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const getTimeSlotList = async (data) => {
  try {
    const responce = await http.post("web/studio/get_weekdays_timeslot_post", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const cart_list = async (data) => {
  try {
    const responce = await http.post("web/studio/cart_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const service_status_change = async (data) => {
  try {
    const responce = await http.post("web/studio/service_status_change", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const update_recurring_amount = async (data) => {
  try {
    const responce = await http.post("web/studio/update_recurring_amount", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const workshop_appointment_cancel_API = async (data) => {
  try {
    const responce = await http.post("web/studio/workshop_appointment_cancel", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const service_appointment_cancel = async (data) => {
  try {
    const responce = await http.post("web/studio/service_appointment_cancel", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const clover_pay_checkout_single = async (data) => {
  try {
    const responce = await http.post("web/studio/clover_pay_checkout_single", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const clover_pay_refund = async (data) => {
  try {
    const responce = await http.post("web/studio/clover_pay_refund", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const pay_refund = async (data) => {
  try {
    const responce = await http.post("web/studio/pay_refund", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const pay_at_desk = async (data) => {
  try {
    const responce = await http.post("web/studio/pay_at_desk", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const add_instructor = async (data) => {
  try {
    const responce = await http.post("web/studio/add_instructor", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const create_website = async (data) => {
  try {
    const responce = await http.post("web/studio/create_website", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const update_website = async (data) => {
  try {
    const responce = await http.post("web/studio/update_website", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const update_website_otherSection_API = async (data) => {
  try {
    const responce = await http.post("web/studio/update_website_otherSection", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const delete_website_otherSection_API = async (data) => {
  try {
    const responce = await http.post("web/studio/website_delete_other_section", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const website_update_order_other_sectionAPI = async (data) => {
  try {
    const responce = await http.post("web/studio/website_update_order_other_section", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const new_client_signup = async (data) => {
  try {
    const responce = await http.post("web/studio/new_client_signup", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const class_schedule_detail = async (data) => {
  try {
    const responce = await http.post("web/studio/class-schedule-detail", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const service_scheduling_time_slot = async (data) => {
  try {
    const responce = await http.post("web/studio/service_scheduling_time_slot", data);
    if (responce) {
      // if (responce.data.status == 1) {
      return responce;
      // } else {
      //   Swal.fire({
      //     html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-center mt-4 textWhite">
      //     Sorry
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //   No appointment times available on this day </small></p>
      //   `,
      //     showClass: {
      //       popup: 'custom-dialog-container',
      //     },
      //     customClass: {
      //       confirmButton: 'custom-button custom-button-size modalbtn',
      //     },
      //   });
      // }
    }
  } catch (error) {
    alert(error);
  }
}


export const add_client = async (data) => {
  try {
    const responce = await http.post("web/studio/add_client", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const update_client = async (data) => {
  try {
    const responce = await http.post("web/studio/update_client", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



// ******************* Studio User API ****************** //

export const InstructorList = async (data) => {
  try {
    const responce = await http.post("web/studio/register_instructor_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
      // else {
      //   Swal.fire({
      //     html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-uppercase text-center mt-4 textWhite">
      //     Error !!!
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //  ${responce.data.message} </small></p>
      //   `,
      //     showClass: {
      //       popup: 'custom-dialog-container',
      //     },
      //     customClass: {
      //       confirmButton: 'custom-button custom-button-size modalbtn',
      //     },
      //   });
      // }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_shift = async (data) => {
  try {
    const responce = await http.post("web/studio/add_shift", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const set_notification = async (data) => {
  try {
    const responce = await http.post("web/studio/set_notification", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const passes_status_change = async (data) => {
  try {
    const responce = await http.post("web/studio/passes_status_change", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          ERROR
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const buy_now_workshop_cash = async (data) => {
  try {
    const responce = await http.post("web/studio/buy_now_workshop_cash", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          ERROR
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_services = async (data) => {
  try {
    const responce = await http.post("web/studio/add_services", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const products_list = async (data) => {
  try {
    const responce = await http.post("web/product/products_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
      // else {
      // errorAlert(responce.data.message);
      //   Swal.fire({
      //     html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-uppercase text-center mt-4 textWhite">
      //     Error !!!
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //  ${responce.data.message} </small></p>
      //   `,
      //     showClass: {
      //       popup: 'custom-dialog-container',
      //     },
      //     customClass: {
      //       confirmButton: 'custom-button custom-button-size modalbtn',
      //     },
      //   });
      // }
    }
  } catch (error) {
    alert(error);
  }
}

export const products_details = async (data) => {
  try {
    const responce = await http.post("web/product/products_details", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const update_product = async (data) => {
  try {
    const responce = await http.post("web/product/update_product", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const products_add = async (data) => {
  try {
    const responce = await http.post("web/product/products_add", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_instructor_class = async (data) => {
  try {
    const responce = await http.post("web/report/get_instructor_class_new", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    } else console.log("resp-->", responce);
  } catch (error) {
    alert(error);
  }
}

export const get_pass_list_by_class = async (data) => {
  try {
    const responce = await http.post("web/report/get_pass_by_class_new", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    } else console.log("resp-->", responce);
  } catch (error) {
    alert(error);
  }
}

export const get_workshop_schedule_calendar = async (data) => {
  try {
    const responce = await http.post("web/studio/get_workshop_schedule_calendar", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    } else console.log("resp-->", responce);
  } catch (error) {
    alert(error);
  }
}

export const get_instructor_chart = async (data) => {
  try {
    const responce = await http.post("web/report/get_instructor_chart", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    } else console.log("resp-->", responce);
  } catch (error) {
    alert(error);
  }
}

export const get_product_report = async (data) => {
  try {
    const responce = await http.post("web/report/get_product_report", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else return responce;
    } else console.log("resp-->", responce);
  } catch (error) {
    alert(error);
  }
}


export const upload_instructor_csv = async (data) => {
  try {
    const responce = await http.post("web/studio/upload_instructor_csv", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${(responce.data.error_type) === "string"
              ?
              responce.data.message
              :
              (responce.data.message).map((e) => (e.message))} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const getInstructorList = async (data) => {
  try {
    const responce = await http.post("web/studio/instructor_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const getSaveCard = async (data) => {
  try {
    const responce = await http.post("webservices/Payment/cardGet", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const book_services = async (data) => {
  try {
    const responce = await http.post("web/studio/book_services", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_member_list = async (data) => {
  try {
    const responce = await http.post("web/studio/get_member_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const business_location_update = async (data) => {
  try {
    const responce = await http.post("web/studio/business_location_update", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const business_location_update_NEW = async (data) => {
  try {
    const responce = await http.post("web/studio/business_location_update_NEW", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const get_pass_type = async (data) => {
  try {
    const responce = await http.post("web/studio/get_pass_type", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_pass_name = async (data) => {
  try {
    const responce = await http.post("web/report/getallpasses_new", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_pass_details = async (data) => {
  try {
    const responce = await http.post("web/studio/get_pass_details", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const cardGet = async (data) => {
  try {
    const responce = await http.post("webservices/api/cardGet", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const passes_list = async (data) => {
  try {
    const responce = await http.post("web/studio/passes_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const free_passes_list = async (data) => {
  try {
    const responce = await http.post("web/studio/free_passes_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const update_passes = async (data) => {
  try {
    const responce = await http.post("web/studio/update_passes", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const remove_services_instructor = async (data) => {
  try {
    const responce = await http.post("web/studio/remove_services_instructor", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_otherinstructor = async (data) => {
  try {
    const responce = await http.post("web/studio/add_otherinstructor", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const change_business_instructor_status = async (data) => {
  try {
    const responce = await http.post("web/studio/change_business_instructor_status", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_business_location_detail = async (data) => {
  try {
    const responce = await http.post("web/studio/get_business_location_detail", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_otherinstructor = async () => {
  try {
    const responce = await http.get("web/studio/get_otherinstructor");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const InstructorChangeStatus = async (data) => {
  try {
    const resp = await http.post('web/studio/change_register_instructor_status', data);

    if (resp && resp.data && resp.data.status == 1) {
      return resp.data;
    } else {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    }
  } catch (error) {
    alert(error);
  }
}

export const transectionList = async (data) => {
  try {
    const resp = await http.post('web/studio/transaction_list', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const transectionList_new = async (data) => {
  try {
    const resp = await http.post('web/studio/transaction_list_new', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const failed_transaction_list_new_API = async (data) => {
  try {
    const resp = await http.post('web/studio/failed_transaction_list_new', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const changePassword_API = async (data) => {
  try {
    const resp = await http.post('web/studio/changepassword', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else errorAlert(resp.data.message);
    }
  } catch (error) {
    alert(error);
  }
}


export const pass_sold_new = async (data) => {
  try {
    const resp = await http.post('web/studio/pass_sold_new', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const transectionDetail = async (data) => {
  try {
    const resp = await http.post('web/studio/get_customer_payment_requests', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const update_class_passes = async (data) => {
  try {
    const resp = await http.post('web/studio/update_class_passes', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const customer_payment_request = async (data) => {
  try {
    const resp = await http.post('web/studio/get_customer_payment_requests_details', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }

  } catch (error) {
    alert(error);
  }
}

export const customer_request_list = async (data) => {
  try {
    const resp = await http.post('/web/studio/become_a_member_customer_list', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}

// End User Studio 

// Start Setting Part

export const client_list = async (data) => {
  try {
    const resp = await http.post('/web/studio/client_list', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
      //   else {
      //     Swal.fire({
      //       html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-uppercase text-center mt-4 textWhite">
      //     Error !!!
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //  ${resp.data.message} </small></p>
      //   `,
      //       showClass: {
      //         popup: 'custom-dialog-container',
      //       },
      //       customClass: {
      //         confirmButton: 'custom-button custom-button-size modalbtn',
      //       },
      //     });
      //   }
    }
  } catch (error) {
    alert(error);
  }
}

export const edit_instructor = async (data) => {
  try {
    const resp = await http.post('/web/studio/edit_instructor', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const list_shift = async (data) => {
  try {
    const resp = await http.post('/web/studio/list_shift', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
      //    else {
      //     Swal.fire({
      //       html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-uppercase text-center mt-4 textWhite">
      //     Error !!!
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //  ${resp.data.message} </small></p>
      //   `,
      //       showClass: {
      //         popup: 'custom-dialog-container',
      //       },
      //       customClass: {
      //         confirmButton: 'custom-button custom-button-size modalbtn',
      //       },
      //     });
      //   }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_class_schedule_calendar = async (data) => {
  try {
    const resp = await http.post('/web/studio/get_class_schedule_calendar', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const get_shift_schedule_calendar = async (data) => {
  try {
    const resp = await http.post('/web/studio/get_shift_schedule_calendar', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_class = async (data) => {
  try {
    const resp = await http.post('/web/studio/add_class', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const instructor_details = async (data) => {
  try {
    const resp = await http.post('/web/studio/instructor_details', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const business_class_time = async (data) => {
  try {
    const resp = await http.post('/web/studio/business_class_time', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const avalible_instructor = async (data) => {
  try {
    const resp = await http.post('/web/studio/avalible_instructor', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_weekdays = async () => {
  try {
    const resp = await http.get('/web/studio/get_weekdays');
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_business_categories = async (data) => {
  try {
    const resp = await http.post('/web/studio/get_business_categories', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const getNotifiation = async () => {
  try {
    const responce = await http.get("/web/studio/get_notification");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const update_class_schedule = async (data) => {
  try {
    const responce = await http.post("/web/studio/update_class_schedule", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const class_scheduling = async (data) => {
  try {
    const responce = await http.post("/web/studio/class_scheduling", data);
    if (responce) {
      return responce;
    }
  } catch (error) {
    console.log(error);
  }
}


export const class_scheduling_update = async (data) => {
  try {
    const responce = await http.post("/web/studio/class_scheduling_update", data);
    if (responce) {
      return responce;
    }
  } catch (error) {
    console.log(error);
  }
}


export const update_class = async (data) => {
  try {
    const responce = await http.post("/web/studio/update_class", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }

    }

  } catch (error) {
    alert(error);
  }
}


export const Pause_Passes = async (data) => {
  try {
    const responce = await http.post("/web/studio/recurring_pass_pause_less_then_thirty", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }

    }

  } catch (error) {
    alert(error);
  }
}

export const get_recurring_amount_list = async () => {
  try {
    const responce = await http.get("/web/studio/get_recurring_amount_list");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }

    }

  } catch (error) {
    alert(error);
  }
}

export const Cancel_Passes = async (data) => {
  try {
    const responce = await http.post("/web/studio/recurring_pass_expiry", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }

    }

  } catch (error) {
    alert(error);
  }
}


export const studio_forgot_password = async (data) => {
  try {
    const responce = await http.post("/web/studio/forgot_password", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }

    }

  } catch (error) {
    alert(error);
  }
}

export const update_multiple_business_status = async (data) => {
  try {
    const responce = await http.post("/web/studio/update_multiple_business_status", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const cancel_single_class = async (data) => {
  try {
    const responce = await http.post("/web/studio/cancel_single_class", data);
    if (responce) {
      if (responce.data.status === 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }

  } catch (error) {
    alert(error);
  }
}



//  Studio Cart

export const book_appointment = async (data) => {
  try {
    const resp = await http.post('/web/studio/services_list', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const update_services_instructor = async (data) => {
  try {
    const resp = await http.post('/web/studio/update_services_instructor', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const update_services_client_visibilty = async (data) => {
  try {
    const resp = await http.post('/web/studio/update_services_client_visibilty', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Excuse Us!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${"We are working on an important update.  Please try again soon. "} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const getUserProfileInfo = async () => {
  try {
    const responce = await http.get("web/studio/get_profile");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const family_member_purchase_details = async (data) => {
  try {
    const resp = await http.post("/web/studio/family_member_purchase_details", data);
    if (resp.data.status == 1) {
      return resp;
    }
  } catch (error) {
    alert(error);
  }
}

export const Studio_edit_member = async (data) => {
  try {
    const resp = await http.post("/web/studio/edit_member", data);
    if (resp.data.status == 1) {
      return resp;
    }
  } catch (error) {
    alert(error);
  }
}


export const Studio_update_pass_expiry_byid = async (data) => {
  try {
    const resp = await http.post("/web/studio/update_pass_expiry_byid", data);
    if (resp.data.status == 1) {
      return resp;
    }
  } catch (error) {
    alert(error);
  }
}

export const Studio_add_member = async (data) => {
  try {
    const resp = await http.post("/web/studio/add_member", data);
    if (resp.data.status == 1) {
      return resp;
    }
  } catch (error) {
    alert(error);
  }
}


export const get_relations = async (data) => {
  try {
    const resp = await http.post("/web/studio/get_relations", data);
    if (resp.data.status == 1) {
      return resp;
    }
  } catch (error) {
    alert(error);
  }
}


export const getClass_list = async (data) => {
  try {
    const responce = await http.post("web/studio/today_class_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const studio_clover_generate_token = async (data) => {
  try {
    const responce = await http.post("web/studio/clover_generate_token", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const clover_access = async (data) => {
  try {
    const responce = await http.post("web/studio/clover_access", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const service_detail = async (data) => {
  try {
    const responce = await http.post("web/report/service_detail", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else console.log("Resp-->", responce);
    }
  } catch (error) {
    alert(error);
  }
}


export const service_detail_new = async (data) => {
  try {
    const resp = await http.post("web/report/service_detail_new", data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const getserviceClient = async (data) => {
  try {
    const responce = await http.post("web/report/getserviceclient_new", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else console.log("Resp-->", responce);
    }
  } catch (error) {
    alert(error);
  }
}

export const shift_cancel = async (data) => {
  try {
    const responce = await http.post("web/studio/shift_cancel", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else console.log("Resp-->", responce);
    }
  } catch (error) {
    alert(error);
  }
}

export const class_list = async (data) => {
  try {
    const responce = await http.post("web/studio/class_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const testimonials_list = async (data) => {
  try {
    const responce = await http.post("web/studio/testimonials_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const get_room_location = async (data) => {
  try {
    const responce = await http.post("web/studio/get_room_location", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_passes_according_to_category = async (data) => {
  try {
    const responce = await http.post("web/studio/get_passes_according_to_category", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const update_cart = async (data) => {
  try {
    const responce = await http.post("web/studio/update_cart", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const remove_cart = async (data) => {
  try {
    const responce = await http.post("web/studio/remove_cart", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_service_schedule_calendar = async (data) => {
  try {
    const resp = await http.post("web/studio/get_service_schedule_calendar", data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const clover_buy_now_services = async (data) => {
  try {
    const resp = await http.post("web/studio/clover_buy_now_services", data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const send_appointment_mail = async (data) => {
  try {
    const resp = await http.post("web/studio/send_appointment_mail", data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${resp.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const getClassDetails = async (data) => {
  try {
    const responce = await http.post("web/studio/class_details", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const sendWebSiteCreationMailAPI = async (data) => {
  try {
    const responce = await http.post("web/studio/sendWebSiteCreationMail", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_passes = async (data) => {
  try {
    const responce = await http.post("web/studio/add_passes", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
      //  else {
      //   Swal.fire({
      //     html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-uppercase text-center mt-4 textWhite">
      //     Error !!!
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //  ${responce.data.message} </small></p>
      //   `,
      //     showClass: {
      //       popup: 'custom-dialog-container',
      //     },
      //     customClass: {
      //       confirmButton: 'custom-button custom-button-size modalbtn',
      //     },
      //   });
      // }
    }
  } catch (error) {
    alert(error);
  }
}

export const workshop_scheduling = async (data) => {
  try {
    const responce = await http.post("web/studio/workshop_scheduling", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${responce.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const search_customer_list = async (data) => {
  try {
    const responce = await http.post("web/studio/search_customer_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const search_customer = async (data) => {
  try {
    const responce = await http.post("web/studio/search_customer", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const search_customer_details = async (data) => {
  try {
    const responce = await http.post("web/studio/search_customer_details", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const waiver_customer = async (data) => {
  try {
    const responce = await http.post("web/studio/waiver_customer", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const waiver_customer_family = async (data) => {
  try {
    const responce = await http.post("web/studio/waiver_customer_family", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const workshop_scheduled_list = async (data) => {
  try {
    const responce = await http.post("web/studio/workshop_scheduled_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_customer_profile = async (data) => {
  try {
    const responce = await http.post("web/studio/get_customer_profile", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const search_customer_for_workshop = async (data) => {
  try {
    const responce = await http.post("web/studio/search_customer_for_workshop", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const studio_country_list = async () => {
  try {
    const responce = await http.get("webservices/api/get_countries");
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_countries = async () => {
  try {
    const resp = await http.post('web/studio/get_countries');
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
      //    else {
      //     Swal.fire({
      //       html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-uppercase text-center mt-4 textWhite">
      //     Error !!!
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //  ${resp.data.message} </small></p>
      //   `,
      //       showClass: {
      //         popup: 'custom-dialog-container',
      //       },
      //       customClass: {
      //         confirmButton: 'custom-button custom-button-size modalbtn',
      //       },
      //     });
      //   }
    }
  } catch (error) {
    alert(error);
  }
}




export const update_pass_remaining_count = async (data) => {
  try {
    const resp = await http.post('web/studio/update_pass_remaining_count', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const customer_profile_update = async (data) => {
  try {
    const resp = await http.post('web/studio/customer_profile_update', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const profile_update = async (data) => {
  try {
    const resp = await http.post('web/studio/profile_update', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const update_pass_expiry = async (data) => {
  try {
    const resp = await http.post('web/studio/update_pass_expiry', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const workshop_scheduling_cancel = async (data) => {
  try {
    const resp = await http.post('web/studio/workshop_scheduling_cancel', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const business_workshop_master_updateAPI = async (data) => {
  try {
    const resp = await http.post('web/studio/business_workshop_master_update', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const business_opening_closing_time = async (data) => {
  try {
    const resp = await http.post('web/studio/business_opening_closing_time', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const add_cart = async (data) => {
  try {
    const resp = await http.post('web/studio/add_cart', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}



export const add_free_pass = async (data) => {
  try {
    const resp = await http.post('web/studio/add_free_pass', data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      } else {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const get_customer_list = async () => {
  try {
    const resp = await http.post('web/studio/get_customer_list');
    if (resp) {
      if (resp.data.status == 1) {
        return resp.data;
      }
      //   else {
      //     Swal.fire({
      //       html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      //     <h4 class="title text-uppercase text-center mt-4 textWhite">
      //     Error !!!
      //   </h4>
      //   <p class="text-center my-4 textcolor">
      //   <small class="textWhite">
      //  ${resp.data.message} </small></p>
      //   `,
      //       showClass: {
      //         popup: 'custom-dialog-container',
      //       },
      //       customClass: {
      //         confirmButton: 'custom-button custom-button-size modalbtn',
      //       },
      //     });
      //   }
    }
  } catch (error) {
    alert(error);
  }
}


export const changeTimeStampToUTCDate = (date) => {
  const info = moment.unix(date).format("LT");
  return info;
}

const sTimeFormat = () => {
  return 12;
}

const changeTimeStampToUTCFull = (time) => {
  const timeFormat = sTimeFormat();
  if (timeFormat == 24) {
    const info = moment.unix(time).format("YYYY-MM-DD H:mm");
    return info;
  } else {
    const zoneArea = moment.tz.guess();
    const info = moment.unix(time).tz(zoneArea).format("YYYY-MM-DD H:mm");
    return info;
  }
}

export const checkCurrentDateFilter = (scheduleDate, st = "", ed = "") => {
  const cur = moment().format("Y-MM-DD");
  const currentDate = new Date(cur);
  const scheduleCreate = new Date(scheduleDate);

  if (scheduleCreate >= currentDate) {
    const start = changeTimeStampToUTCFull(st);
    const end = changeTimeStampToUTCFull(ed);
    const currentTime = moment();
    const endTime = moment(end, "DD/MM/YYYY HH:mm a");
    if (currentTime.isAfter(end)) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

const setTimeFormat = () => {
  return 12;
}

const setTimeFormat24 = () => {
  return 24;
}

export const changeTimeStampToUTC = (time) => {
  const timeFormat = setTimeFormat();
  if (timeFormat == 24) {
    const info = moment.unix(time).format("H:mm");
    return info;
  } else {
    const zoneArea = moment.tz.guess();
    const info = moment.unix(time).tz(zoneArea).format("LT");
    return info;
  }
}

export const changeTimeStampToUTC24 = (time) => {
  const timeFormat = setTimeFormat24();
  if (timeFormat == 24) {
    const info = moment.unix(time).format("HH:mm");
    return info;
  } else {
    const zoneArea = moment.tz.guess();
    const info = moment.unix(time).tz(zoneArea).format("LT");
    return info;
  }
}

export const newUserRedirect = (type, id) => {
  window.location.href = process.env.REACT_APP_BASE_URL + "signin";
}


export const toTimestamp = (date) => {
  const datum = Date.parse(date);
  return datum / 1000;
}

export const utcToLocalTime = (utcTime) => {
  const getFormat = moment.unix(utcTime).format("LL");
  return getFormat;
}

export const ToDate = (date) => {
  var day = date.toString().slice(4, 15);
  var day1 = day.split(" ");
  var newmonth = monthArray.findIndex((rank) => rank === day1[0]) + 1;
  if (newmonth < 10) {
    newmonth = "0" + newmonth;
  }
  var dob = day1[2] + "-" + newmonth + "-" + day1[1];
  return dob;
}
import React, { useEffect } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { customer_request_list } from "../../../../../Studio-Services/Studio_Services";
import { useState } from "react";
import moment from "moment";
import { ClientTable } from "./ClientTable";
import { Link } from "react-router-dom";
import { ClientListTable } from "./ClientListTable";

export const Clientoflist = () => {

  const [clientlist, setClientlist] = useState([]);
  const [array, setArray] = useState([]);
  const [loder, setLoder] = useState(false);
  const getClientList = async () => {
    const pid = { 'pageid': '1' };
    let abcd = [];
    setLoder(true);
    const resp = await customer_request_list(pid);
    if (resp) {
      setClientlist(resp.data);
      resp.data.forEach((element) => {
        abcd.push({
          Name: (
            <>
              <Link to='/studio-customer-profile' state={{ id: element.id }} style={{ cursor: 'pointer' }}>
                <img className="img-fluid table_user" src={element.profile_img}  alt=''/>
                <span className="ps-4">{element.name}</span>
              </Link>
              {/* <span className="ps-2">{element.lastname}</span> */}
            </>
          ),
          myName: element.name,
          dob: element.date_of_birth ? moment(element.date_of_birth).format("DD MMM YYYY") : "",
          Email: element.myEmail,
          Age: element.age,
          Gender: element.gender,
          Start_Date:element.reg_date ? moment.unix(element.reg_date).format('DD-MMM-YYYY') :"",
          Status: element.user_status,
        });
      });
      setArray(abcd);
      setLoder(false);
    } else setArray(abcd);
    setLoder(false);
  };

  useEffect(() => {
    getClientList();
  }, []);

    const columns = [
      { dataField: "Name", text: "Name" },
      { dataField: "dob", text: "Date of Birth " },
      { dataField: "Email", text: "Email" },
      { dataField: "Age", text: "Age" },
      { dataField: "Gender", text: "Gender" },
      { dataField: "Start_Date", text: "Start Date" },
      { dataField: "Status", text: "Status" },
    ];

  // const array = [];

  // if (clientlist) {

  // }

  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container fluid>
                      <Row className="mt-3 clinet-list-tab">
                        <Col>
                          {/* <ClientTable  clientlist={clientlist} datas={array} columns={columns}/> */}
                          <ClientListTable clientlist={clientlist} datas={array} columns={columns}/>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

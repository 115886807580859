import React, { useMemo, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { SharedButton } from "./Button";
import { RiEditFill } from "react-icons/ri";
import { update_class_schedule } from "../Studio-Services/Studio_Services";
import { errorAlert } from "../Static_Services/Alertmsg";

export const ClassScheduleModal = (props) => {

  const preinfo = props.preinfo ? props.preinfo : "";
  const list = props.list ? props.list : '';
  const classDetail = props.classDetail ? props.classDetail : "";


  const [gettype, setGettype] = useState();
  const [instructorId, setInstructorId] = useState();

  const typehandler = (event) => {
    setGettype(event.target.value);
  }
  const instuctHandler = (event) => {
    setInstructorId(event.target.value);
  }


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = [
    { value: "1", label: "Substitued this one class" },
    { value: "2", label: "Update for all future classes" },
  ];

  let instructorList = [];


  instructorList = useMemo(() => {
    return list.map((e) => ({ value: e.user_id, label: `${e.name} ${e.lastname}` }));
  }, [list]);




  const updateInstructor = async () => {
    const class_id = preinfo.class_id;
    const schedule_id = preinfo.schedule_id;
    const instructor_id = instructorId;
    const type = gettype;

    if (!instructor_id) { errorAlert("Please select an instructor");return false};
    if (!type) { errorAlert("Please select an type");return false };

   
   
        const schedule_list = [];
        if (type === "2") {
          (classDetail.upcomming).forEach((value) => {
            schedule_list.push(value.id)
          });
          const formData = {
            class_id: class_id,
            schedule_id: schedule_id,
            instructor_id: instructor_id,
            type: type,
            schedule_list: schedule_list
          }
          const resp = await update_class_schedule(formData);
          if (resp.data.status === 1) {
            setGettype();
            setInstructorId();
            setShow(false);
            props.updateList();
          }
        } else {
          const formData = {
            class_id: class_id,
            schedule_id: schedule_id,
            instructor_id: instructor_id,
            type: type
          }
          const resp = await update_class_schedule(formData);
          if (resp.data.status === 1) {
            setGettype();
            setInstructorId();
            setShow(false);
            props.updateList();
          }
        }
  }

  return (
    <>
      <SharedButton
        className={"btn btn-outline FontIconGreen"}
        type={"button"}
        variant={"Outline"}
        iconNode={<RiEditFill size={20} />}
        onClick={handleShow}
      />
      <Modal show={show} onHide={handleClose} className="classScheduleModal">
    
        <Modal.Body>      
            <Modal.Title>Update Instructor status</Modal.Title>
          <Row>
            <Col md={6}>
            <label>Type</label>
              <select className="form-select" onChange={typehandler} aria-label="Default select example" value={gettype} >
                <option value=''> Select </option>
                {options ? options.map((option) => (
                  <option key={option.value} value={option.value ? option.value : ''}>
                    {option.label ? option.label : ""}
                  </option>
                )) : ''}
              </select>
            </Col>
            <Col md={6}>
            <label>Select instructor</label>
              <select className="form-select" onChange={instuctHandler} aria-label="Default select example" value={instructorId} >
                <option value=''> Select </option>
                {instructorList ? instructorList.map((value) => (
                  <option key={value.value} value={value.value ? value.value : ''}>
                    {value.label ? value.label : ""}
                  </option>
                )) : ''}
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
              <SharedButton title={"Save"} variant={"primary"} onClick={updateInstructor} type={"button"} className={"SaveBtn w-100"} />
            </Col>
            <Col>
              <SharedButton title={"Cancel"} variant={"secondary"} type={"button"} className={"CancelBtn w-100"} onClick={handleClose} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
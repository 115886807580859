import React, { useState } from "react";
import { Header } from "../../../../public/containers/header/Header";
import { Footer } from "../../../../public/containers/footer/Footer";
import { Form } from "react-bootstrap";
import { SharedButton } from "../../../../sharedComponents/Button";
import { InputField } from "../../../../sharedComponents/InputField";
import { successAlert } from "../../../../Static_Services/Alertmsg";
import { changePassword_API } from "../../../../Studio-Services/Studio_Services";
import { useNavigate } from "react-router-dom";

export const ChangePassword = () => {

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();


  const [passerror, setPasserror] = useState({ old_password: "", new_password: "", conf_password: "", });
  const [updatepass, setUpdatepassPass] = useState({ old_password: "", new_password: "", conf_password: "", });

  const UpdateHandler = (e) => {
    const { name, value } = e.target;
    setUpdatepassPass((pre) => ({ ...pre, [name]: value }));
    setPasserror((pre) => ({ ...pre, [name]: '' }));
  }



  const passHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const old_password = updatepass?.old_password;
    const new_password = updatepass?.new_password;
    const conf_password = updatepass?.conf_password;
    if (!old_password) {
      isValid = 2;
      setPasserror((pre) => ({ ...pre, old_password: 'Password is required' }));
    }
    if (!new_password) {
      isValid = 4;
      setPasserror((pre) => ({ ...pre, new_password: 'New Password is required' }));
    }
    if (!conf_password) {
      isValid = 4;
      setPasserror((pre) => ({ ...pre, conf_password: 'Confirm Password is required' }));
    }

    if (new_password !== conf_password) {
      isValid = 5;
      setPasserror((pre) => ({ ...pre, conf_password: 'Password And Confirm Password not match' }));
    }

    if (isValid === 1) {
      var detail = {
        "old_password": old_password,
        "new_password": conf_password,
      };
      const resp = await changePassword_API(detail);
      if (resp) {
        successAlert(resp.data.message);
        localStorage.setItem('id', '');
        localStorage.removeItem('id');
        localStorage.removeItem('st_userData');
        localStorage.removeItem('Authorization');
        localStorage.removeItem('business_id');
        localStorage.removeItem('marchant_id');
        localStorage.removeItem('cl_token_expire');
        localStorage.removeItem('business_token');
        localStorage.removeItem('list_token');
        localStorage.removeItem('month_token');
        localStorage.clear();
        navigate('/studiologin');
        
        // setPassModal(false);
      }
    }
  }


  return (
    <>
      <Header />
      <div className="middle-content-wrp">
        <div className="signwrapp-back-layer">
          <img src="assets/images/pictures/watermark_bg.png" alt="Watermark Background" />
        </div>
        <div className="container relative setminheight d-flex align-items-center">
          <div className="row justify-content-center flex-fill">
            <div className="col-md-6 col-lg-5 col-xl-4">
              <div className="middle-innerbox py-4 px-4">
                <div className="modal-logo text-center pb-1 pt-1">
                  <img src="clientassets/img/logo_green_small.png" />
                </div>
                <h3 className="title text-center">Change Password</h3>
                <form onSubmit={passHandler} className="mt-3">
                  <div className="form-group">
                    <p className="text-left font-14 mb-0 ml-2"> Old Password   <span style={{ color: 'red' }}>*</span> </p>
                    <div style={{ position: "relative" }}>
                      <input
                        id="password-field"
                        type={showOldPassword ? "text" : "password"}
                        style={{
                          color: "black",
                          width: "100%",
                          paddingRight: "40px", // Add padding to avoid overlapping with the icon
                        }}
                        className="form-control"
                        name="old_password"
                        value={updatepass.old_password}
                        formControlName="old_password"
                        onChange={UpdateHandler}
                      />
                      <span
                        className={`fa fa-fw ${showOldPassword ? "fa-eye-slash" : "fa-eye"}`}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          color: "#333",
                        }}
                        onClick={() => setShowOldPassword(!showOldPassword)} // Toggle password visibility
                      ></span>
                    </div>
                    <small className="text-danger">{passerror.old_password}</small>

                  </div>

                  <div className="form-group">
                    <p className="text-left font-14 mb-0 ml-2"> New Password  <span style={{ color: 'red' }}>*</span></p>
                    <div style={{ position: "relative" }}>
                      <input
                        id="password-field"
                        type={showNewPassword ? "text" : "password"}
                        style={{
                          color: "black",
                          width: "100%",
                          paddingRight: "40px", // Add padding to avoid overlapping with the icon
                        }}
                        className="form-control"
                        name="new_password"
                        value={updatepass.new_password}
                        formControlName="new_password"
                        onChange={UpdateHandler}
                      />
                      <span
                        className={`fa fa-fw ${showNewPassword ? "fa-eye-slash" : "fa-eye"}`}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          color: "#333",
                        }}
                        onClick={() => setShowNewPassword(!showNewPassword)} // Toggle password visibility
                      ></span>
                    </div>
                    <small className="text-danger">{passerror.new_password}</small>
                  </div>

                  <div className="form-group">
                    <p className="text-left font-14 mb-0 ml-2"> Confirm Password  <span style={{ color: 'red' }}>*</span> </p>
                    <div style={{ position: "relative" }}>
                      <input
                        id="password-field"
                        type={showConfirmPassword ? "text" : "password"}
                        style={{
                          color: "black",
                          width: "100%",
                          paddingRight: "40px", // Add padding to avoid overlapping with the icon
                        }}
                        className="form-control"
                        name="conf_password"
                        value={updatepass.conf_password}
                        onChange={UpdateHandler}
                      />
                      <span
                        className={`fa fa-fw ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          color: "#333",
                        }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle password visibility
                      ></span>
                    </div>
                    <small className="text-danger">{passerror.conf_password}</small>

                    <div className="form-group">
                      <button type="submit" className="btn text-uppercase w-100 mt-3 btn-green-grad">  update </button>
                      {/* <button type="button" data-dismiss="modal" id="btn_close" className="btn text-uppercase w-100 mt-3"
                        data-backdrop="static" data-keyboard="false" onClick={() => setPassModal(false)}> close </button> */}
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

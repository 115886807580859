import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { Row, Col, Container, Form } from "react-bootstrap";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { MyDatePicker } from "../../../../../sharedComponents/MyDatePicker";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { get_pass_details, get_pass_type, update_passes } from "../../../../../Studio-Services/Studio_Services";
import moment from "moment";
import { SharedMultiSelect } from "../../../../../sharedComponents/SharedMultiSelect";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { InputTypeTax } from "../../../../../sharedComponents/InputTypeTax";
import { yesNoOption } from "../../../../../sharedComponents/MyHelperFunction";
import Tooltips from "../../../../../client-staff/Components/CommanPages/Tooltips";

export const PassUpdateNew = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchTitle, setSearchTitle] = useState();
    const [classes, setClasses] = useState();
    const [showEXP, setShowEXP] = useState();
    const [classOption, setClassOption] = useState([]);
    const [skillsdata, setSkillsdata] = useState();


    const opyesno = [
        { value: "yes", label: <>Yes</> },
        { value: "no", label: <>No</> },
    ]
    const ageOPT = [
        { value: "Over", label: <>Over </> },
        { value: "Under", label: <>Under </> },
        { value: "None", label: <>None </> },
    ]

    const [prd, setPrd] = useState(
        {
            pass_name: "", pass_type: "", pass_sub_type: "", purchase_date: "", passexpiry: "", tax1: "", tax2: "",
            tax1_rate: "", tax2_rate: "", amount: "", is_client_visible: "", is_one_time_purchase: "", description: "",
            notes: "", age_restriction: "", age_over_under: "", class_details: "", all: '', 'is_recurring_billing': '', autoAdd: "No"
        }
    );
    const [error, setError] = useState(
        {
            pass_name: "", pass_type: "", pass_sub_type: "", purchase_date: "", passexpiry: "", tax1: "", tax2: "",
            tax1_rate: "", tax2_rate: "", amount: "", is_client_visible: "", is_one_time_purchase: "", description: "",
            notes: "", age_restriction: "", age_over_under: "", class_details: "", autoAdd: '',
        }
    )


    const getPassDetails = async (id) => {
        const finalData = { pass_id: id }
        const resp = await get_pass_details(finalData);
        if (resp) {
            setClasses(resp.data.data);
        }
    }


    useEffect(() => {
        if (location && location.state && location.state.data) {
            const pd = location.state.data;
            console.log("pppppppppppppddddddddddddddddd-", pd);
            setPrd(
                {
                    pass_name: pd.pass_name, pass_type: pd.is_recurring_billing === 'yes' ? 'Recurring Punch Card' : pd.pass_type, pass_sub_type: pd.pass_sub_type, purchase_date: pd.purchase_date, passexpiry: pd.pass_validity, tax1: pd.tax1, tax2: pd.tax2,
                    tax1_rate: pd.tax1_rate, tax2_rate: pd.tax2_rate, amount: pd.amount, is_client_visible: pd.is_client_visible, is_one_time_purchase: pd.is_one_time_purchase, description: pd.description,
                    notes: pd.notes, age_restriction: pd.age_restriction, age_over_under: pd.age_over_under, class_details: "", all: pd, is_recurring_billing: pd.is_recurring_billing, autoAdd: pd?.isAutoAdd,
                }
            )
            if (pd && pd.pass_type == "Time Frame") {
                setSearchTitle(<>Number Of Days</>);
                setPrd((pre) => ({ ...pre, pass_sub_type: pd.pass_days }));
                setShowEXP(pd.pass_type);
            } else if (pd && pd.pass_type == "Punch Card") {
                setSearchTitle(<>Number Of Classes</>);
                setPrd((pre) => ({ ...pre, pass_sub_type: pd.pass_days }));
                setShowEXP(pd.pass_type);
            } else if (pd && pd.pass_type == "Recurring Membership") {
                setSearchTitle(<>Number Of Month</>);
            }
            getPassDetails(pd.pass_id)
        }
    }, [location])

    const onHandler = (e) => {
        const { name, value } = e.target;
        setPrd((pre) => ({ ...pre, [name]: value }));
        setError((pre) => ({ ...pre, [name]: '' }));
    }

    const submtHandler = async (e) => {
        e.preventDefault();
        let isValid = 1;
        let ownpassexpiry = 30;
        let tax1Rate = 0;
        let tax2Rate = 0;
        const class_details = [];
        if (skillsdata) { skillsdata.map((e) => { class_details.push(e.value,) }) }

        if (!prd.pass_name) { setError((pre) => ({ ...pre, pass_name: "* Required" })); isValid = 2 }
        if (!prd.tax1) { setError((pre) => ({ ...pre, tax1: "* Required" })); isValid = 5 }
        if (!prd.is_client_visible) { setError((pre) => ({ ...pre, is_client_visible: "* Required" })); isValid = 9 }
        if (!prd.description) { setError((pre) => ({ ...pre, description: "* Required" })); isValid = 10 }
        if (!prd.notes) { setError((pre) => ({ ...pre, notes: "* Required" })); isValid = 11 }


        if ((prd.tax1).toLowerCase() == "yes") {
            if (!prd.tax1_rate) { setError((pre) => ({ ...pre, tax1_rate: "* Required" })); isValid = 6 }
            tax1Rate = prd.tax1_rate;
        }
        if (!prd.tax2) { setError((pre) => ({ ...pre, tax2: "* Required" })); isValid = 7 }
        if ((prd.tax2).toLowerCase() == "yes") {
            if (!prd.tax2_rate) { setError((pre) => ({ ...pre, tax2_rate: "* Required" })); isValid = 8 }
            tax2Rate = prd.tax2_rate;
        }
        if ((prd.tax1).toLowerCase() == "no") {
            if (!prd.tax2_rate) { setError((pre) => ({ ...pre, tax2_rate: "* Required" })); isValid = 8 }
            tax1Rate = 0;
        }
        if ((prd.tax2).toLowerCase() == "no") {
            if (!prd.tax2_rate) { setError((pre) => ({ ...pre, tax2_rate: "* Required" })); isValid = 8 }
            tax2Rate = 0;
        }

        if (isValid === 1) {
            var detail = {
                'pass_id': prd.all.pass_id,
                'pass_name': prd.pass_name,
                'tax1': prd.tax1,
                'tax1_rate': tax1Rate || 0,
                'tax2': prd.tax2,
                'tax2_rate': tax2Rate || 0,
                'is_client_visible': prd.is_client_visible,
                'description': prd.description,
                'notes': prd.notes,
                'class_details': class_details,
                'isAutoAdd': prd?.autoAdd,
            }
            const resp = await update_passes(detail);
            if (resp) {
                Swal.fire({
                    html: `
                    <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                    <h4 class="title text-uppercase text-center mt-4 textWhite">
                      SUCCESS
                    </h4>
                    <p class="text-center my-4 textcolor">
                      <small class="textWhite">
                        ${resp.data.message}
                      </small>
                    </p>
                  `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/list-pass");
                    }
                });
            }
        }

    }


    const optClasses = () => {
        const abc = [];
        if (classes && classes.class) {
            (classes.class).map((e) => {
                abc.push({ value: e.id, label: e.class_name })
            })
        }
        setClassOption(abc);
    }


    useEffect(() => {
        if (classes && classes.class) { optClasses(); }
    }, [classes])


    return (
        <>
            <div className="MainDashboard">
                <div className="MainLayout">
                    <Row>
                        <Sidebar />
                        <Col className="p-0 RightCol">
                            <div className="RightSide">
                                <HeaderDashboard />
                                <div className="innerContent">
                                    <div className="AddPassContent">
                                        <Container>
                                            <Form onSubmit={submtHandler} autoComplete='off'>
                                                <h3 className="mb-5 mt-3">EDIT PASS</h3>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <InputField
                                                            type={"text"}
                                                            name={"pass_name"}
                                                            value={prd.pass_name}
                                                            error={error.pass_name}
                                                            onChange={onHandler}
                                                            lable={"Pass Name"} />
                                                    </Col>
                                                    <Col>
                                                        <InputField
                                                            type={'text'}
                                                            value={prd.pass_type}
                                                            name={"pass_type"}
                                                            readOnly={true}
                                                            lable={"Type of Pass"} />
                                                    </Col>
                                                    {console.log("----searchTitle------",searchTitle,"prd.pass_sub_type-------",prd.pass_sub_type)}
                                                    {searchTitle ?
                                                        <Col>
                                                            <InputField
                                                                name={"pass_sub_type"}
                                                                value={prd.pass_sub_type}
                                                                error={error.pass_sub_type}
                                                                readOnly={true}
                                                                lable={searchTitle} />
                                                        </Col>
                                                        : ""}
                                                    <Col>
                                                        <InputField
                                                            type={"date"}
                                                            name={"purchase_date"}
                                                            readOnly={true}
                                                            value={moment(prd.purchase_date).format('YYYY-MM-DD')}
                                                            error={error.purchase_date}
                                                            lable={"Date available for purchase"}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <InputTypeTax
                                                            name={"amount"}
                                                            type={"number"}
                                                            value={prd.amount}
                                                            minLength={'0'}
                                                            error={error.amount}
                                                            readOnly={true}
                                                            lable={"Amount (In $)"} />
                                                    </Col>

                                                    <Col>
                                                        <SharedSelect
                                                            options={opyesno}
                                                            name={"is_client_visible"}
                                                            value={prd.is_client_visible}
                                                            error={error.is_client_visible}
                                                            onChange={onHandler}
                                                            lable={"Visible to client"} />
                                                    </Col>
                                                    <Col>
                                                        <SharedSelect
                                                            options={opyesno}
                                                            name={"is_one_time_purchase"}
                                                            value={prd.is_one_time_purchase}
                                                            error={error.is_one_time_purchase}
                                                            readOnly={true}
                                                            onChange={onHandler}
                                                            lable={"One time purchase only"} />
                                                    </Col>

                                                </Row>

                                                <Row className="mt-3">
                                                    {showEXP &&
                                                        <Col>
                                                            <InputField
                                                                minLength={"0"}
                                                                name={"passexpiry"}
                                                                type={"number"}
                                                                readOnly={true}
                                                                value={prd.passexpiry}
                                                                error={error.passexpiry}
                                                                onChange={onHandler}
                                                                lable={"Days after purchase it expires"} />
                                                        </Col>}
                                                    <Col>
                                                        <SharedSelect
                                                            options={opyesno}
                                                            name={"tax1"}
                                                            value={(prd.tax1).toLowerCase()}
                                                            onChange={onHandler}
                                                            error={error.tax1}
                                                            lable={"Tax 1 Applicable"} />
                                                    </Col>
                                                    {(prd.tax1).toLowerCase() == "yes" ?
                                                        <Col>
                                                            <InputTypeTax
                                                                name={"tax1_rate"}
                                                                type={"number"}
                                                                value={prd.tax1_rate}
                                                                error={error.tax1_rate}
                                                                minLength={"0"}
                                                                onChange={onHandler}
                                                                lable={"Tax 1 Rate %"} />
                                                        </Col>
                                                        : ""}
                                                    <Col>
                                                        <SharedSelect
                                                            options={opyesno}
                                                            name={"tax2"}
                                                            value={(prd.tax2).toLowerCase()}
                                                            onChange={onHandler}
                                                            error={error.tax2}
                                                            lable={"Tax 2 Applicable"} />
                                                    </Col>

                                                    {(prd.tax2).toLowerCase() == "yes" ?
                                                        <Col>
                                                            <InputTypeTax
                                                                name={"tax2_rate"}
                                                                type={"number"}
                                                                value={prd.tax2_rate}
                                                                error={error.tax2_rate}
                                                                onChange={onHandler}
                                                                minLength={"0"}
                                                                lable={"Tax 2 Rate %"} />
                                                        </Col>
                                                        : ""}

                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <TextArea
                                                            name={"description"}
                                                            error={error.description}
                                                            value={prd.description}
                                                            onChange={onHandler}
                                                            lable={"Description"} />
                                                    </Col>
                                                    <Col>
                                                        <TextArea
                                                            name={"notes"}
                                                            error={error.notes}
                                                            value={prd.notes}
                                                            onChange={onHandler}
                                                            lable={"Notes"} />
                                                    </Col>
                                                    <Col></Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <SharedSelect
                                                            options={ageOPT}
                                                            name={"age_restriction"}
                                                            value={prd.age_restriction}
                                                            error={error.age_restriction}
                                                            onChange={onHandler}
                                                            readOnly={true}
                                                            lable={"Age Restriction"} />
                                                    </Col>
                                                    {(prd.age_restriction).toLowerCase() != "none" ?
                                                        <Col>
                                                            <InputField
                                                                type={"number"}
                                                                minLength={"0"}
                                                                name={"age_over_under"}
                                                                value={prd.age_over_under}
                                                                readOnly={true}
                                                                error={error.age_over_under}
                                                                onChange={onHandler}
                                                                lable={"What age over/under"}
                                                            />
                                                        </Col>
                                                        : ""}
                                                    <Col>                                                  
                                                        <SharedSelect
                                                            options={yesNoOption}
                                                            name={"autoAdd"}
                                                            value={prd.autoAdd}
                                                            error={error.autoAdd}
                                                            onChange={onHandler}
                                                            lable={"Auto Add"} />                                                            
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <h3>Existing Class </h3>
                                                    <hr />

                                                    <div className="existingClassesText">
                                                        <ul>
                                                            <Row>
                                                                {classes && classes.class_id ? (classes.class_id).map((e, index) => (
                                                                    <Col lg={3} md={3} key={index}>
                                                                        <li>{e.class_name}</li>
                                                                    </Col>
                                                                )) : ""}
                                                            </Row>
                                                        </ul>
                                                    </div>

                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <SharedMultiSelect
                                                            labelText="Add More Class"
                                                            setSkillsdata={setSkillsdata}
                                                            name="class_details"
                                                            options={classOption ? classOption : ""}
                                                        />

                                                    </Col>
                                                    <Col></Col>
                                                    <Col></Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <SharedButton
                                                            title={"Update"}
                                                            className={"btn btn-primary w-100"}
                                                            type={"submit"}
                                                            variant={"primary"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <SharedButton
                                                            href={"/list-pass"}
                                                            title={"Back"}
                                                            className={"btn btn-outline w-100"}
                                                            type={"button"}
                                                            variant={"outline"}
                                                        />
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            </Form>
                                        </Container>
                                    </div>
                                </div>
                                <Footer />
                            </div>``
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

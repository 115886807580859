import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { errorAlert } from '../../../../Static_Services/Alertmsg';
import { customer_card_save_API } from '../../../../Studio-Services/Studio_Services';

export default function AddCardModalNew({ showCardModal, setShowCardModal, clientIDS, studioData, setLoder }) {
    const [cardError, setCardError] = useState({ "card_name": "", "card_number": "", "expire_date": "", "cvv": "" });
    const [cardData, setCardData] = useState({ "card_name": "", "card_number": "", "expire_date": "", "cvv": "" });


    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setCardError((pre) => ({ ...pre, expire_date: '' }));
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            if (inputValue.endsWith('/')) {
                setCardData((pre) => ({ ...pre, expire_date: inputValue.slice(0, -1) }));
            } else { setCardData((pre) => ({ ...pre, expire_date: inputValue })); }
        } else {
            if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
                setCardData((pre) => ({ ...pre, expire_date: inputValue + '/' }));
            } else if (inputValue.length === 8) { return; } else { setCardData((pre) => ({ ...pre, expire_date: inputValue })); }
        }
    };


    const handleCardInputChange = (e) => {
        let { name, value } = e.target;
        if (name === 'card_number') {
            value = value.replace(/[^0-9]/g, "");
            if (value?.length > 16) {
                return false;
            }
        } else if (name === 'cvv') {
            value = value.replace(/[^0-9]/g, "");
            if (value?.length > 3) {
                return false;
            }
        }
        setCardData((pre) => ({ ...pre, [name]: value }));
        setCardError((pre) => ({ ...pre, [name]: "" }));
    }

    const addNewCardHandler = async () => {
        let { card_name, card_number, expire_date, cvv } = cardData;
        if (!clientIDS) { errorAlert("Client id is required"); return false; }
        if (!studioData?.business_id) { errorAlert("Business id is required"); return false };
        let isValid = 0;
        if (!card_name) { setCardError((pre) => ({ ...pre, card_name: "Required" })); isValid = 1; };
        if (!card_number) { setCardError((pre) => ({ ...pre, card_number: "Required" })); isValid = 2; };
        if (!expire_date) { setCardError((pre) => ({ ...pre, expire_date: "Required" })); isValid = 3; };
        if (!cvv) { setCardError((pre) => ({ ...pre, cvv: "Required" })); isValid = 4; };

        cvv = cvv.trim();
        card_number = card_number.trim();
        const parts = expire_date.split('/');
        const month = parts[0];
        const year = parts[1];

        let payload = new FormData();
        payload.append('client_id', clientIDS);
        payload.append('business_id', studioData?.business_id,);   
        payload.append('card_name', card_name);
        payload.append('card_number', card_number);
        payload.append('expiry_month', month);
        payload.append('expiry_year', year);
        payload.append('cvv', cvv);
        payload.append('country_code','');
             
        const resp = await customer_card_save_API(payload);
        console.log("------",resp);
       
    }



    return (
        < Modal show={showCardModal} className="modal fade" role="dialog" >
            <div className="modal-body white-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
                <h4 className='title text-uppercase text-center' >Cards Details</h4>

                <div className="row">
                    {/* Card Name */}
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                            <p className="brand-color3 text-left font-14 mb-2" style={{ fontSize: '15px', color: 'black' }}> <b>Name on card <span style={{ color: 'red' }}> *</span> </b></p>
                            <input
                                type="text"
                                className="form-control  "
                                autoComplete="off"
                                name='card_name'
                                value={cardData.card_name}
                                onChange={handleCardInputChange}
                                placeholder="enter name on card"
                            />
                            <small className="text-danger">{cardError.card_name}</small>
                        </div>
                    </div>

                    {/* <!-- Card Number --> */}
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group client-buy-input-cardno">
                            <p className="brand-color3 text-left font-14 mb-2" style={{ fontSize: '15px', color: 'black' }}><b> Card Number  <span style={{ color: 'red' }}> *</span></b> </p>
                            <input
                                type="text"
                                autoComplete="off"
                                className="form-control  "
                                placeholder="4121 4411 3131 8554"
                                name='card_number'
                                value={cardData.card_number}
                                onChange={handleCardInputChange}
                            />
                            <small className="text-danger">{cardError.card_number}</small>
                        </div>
                    </div>

                    {/* <!-- Exp... Dates --> */}
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                            <p className="brand-color3 text-left font-14 mb-2" style={{ fontSize: '15px', color: 'black' }}><b> Valid Till  <span style={{ color: 'red' }}> *</span></b> </p>
                            <input
                                type="text"
                                name={'expire_date'}
                                className="form-control  "
                                placeholder="MM/YYYY"
                                value={cardData.expire_date}
                                onChange={handleInputChange}
                                pattern="^[0-9]{2}/[0-9]{4}"
                                autoComplete="new-password"
                                lable={"Valid Through"}
                            />
                            <small className="text-danger">{cardError.expire_date}</small>
                        </div>
                    </div>
                    {/* <!-- CVV  --> */}
                    <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                            <p className="brand-color3 text-left font-14 mb-2" style={{ fontSize: '15px', color: 'black' }}> <b> Cvv  <span style={{ color: 'red' }}> *</span> </b></p>
                            <input
                                type="password"
                                autoComplete="off"
                                className="form-control  "
                                placeholder="eg. 201"
                                name='cvv'
                                value={cardData.cvv}
                                onChange={handleCardInputChange}
                                maxLength={3}
                            />
                            <small className="text-danger">{cardError.cvv}</small>
                        </div>
                    </div>

                    <div className="w-100 py-3 text-center d-flex">
                        <button type="button" class="btn btn-green-grad flex-fill text-uppercase w-50" onClick={addNewCardHandler}> Save Card </button>
                        <button type="button" class="btn btn-blue-grad flex-fill text-uppercase ml-3  w-50" onClick={() => { setShowCardModal(false) }}>Close</button>
                    </div>
                </div>

                {/* </div> */}
            </div>
        </Modal >
    )
}

import React, { useEffect, useState } from "react";
import { SharedSelect } from "./SharedSelect";
import { InputField } from "./InputField";
import { TextArea } from "./TextArea";
import { SharedButton } from "./Button";
import { Row, Col, Form } from "react-bootstrap";
import { clover_pay_checkout_single, pay_at_desk } from "../Studio-Services/Studio_Services";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import logo from "../Images/logo_white_web.png"
import { adjAmountSubTotal } from "./MyHelperFunction";


export const PaymentDetailForm = ({ data, clientIdsss, setLoder, tablePrice }) => {
  const [crtdetail, setCrtdetail] = useState([]);
  const [fullData, setFullData] = useState();
  const [memberId, setMemberId] = useState();
  const [clientId, setClientId] = useState();
  const navigate = useNavigate();

  const paymodeOP = [
    {
      value: "Cash", label: "Cash"

    },
    {
      value: "POS", label: "POS"
    },
    {
      value: "Pass", label: "Pass"
    },
    {
      value: "GiftCard", label: "GiftCard"
    },
    {
      value: "Check", label: "Check"
    },
    {
      value: "Direct Debit", label: "Direct Debit"
    }
  ]

  const [error, setError] = useState({
    mode: "",
    trid: "",
    notes: ""
  })


  useEffect(() => {
    if (data && data.business_details && data.business_details.cart_details) {
      setCrtdetail(data.business_details.cart_details);
      setFullData(data.business_details);
    }
    setMemberId(localStorage.getItem("memberUserId"));
    if (localStorage.getItem('clientID')) {
      setClientId(localStorage.getItem('clientID'));
    } else {
      setClientId(clientIdsss)
    }

  }, [data]);


  const errorHandler = (e) => {
    const { name, value } = e.target;
    setError((pre) => ({ ...pre, [name]: "" }));
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());
    const mode = fValue.mode;
    const trid = fValue.trid;
    const notes = fValue.notes || 'none';
    let isValid = 1;

    if (!mode) { setError((pre) => ({ ...pre, mode: "* Required" })); isValid = 2; }
    if (!trid) { setError((pre) => ({ ...pre, trid: "* Required" })); isValid = 2; }
    if (!notes) { setError((pre) => ({ ...pre, notes: "* Required" })); isValid = 2; }
    if (isValid === 1) {
      setLoder(true);
      if (crtdetail.length > 0) {

        var cartTemp = [];
        for (var i = 0; i < crtdetail.length; i++) {
          cartTemp.push({
            "cartID": crtdetail[i].cart_id,
            "service_id": crtdetail[i].service_id,
            "service_type": crtdetail[i].service_type,
            "business_id": fullData.business_id,
            "quantity": crtdetail[i].quantity,
            "amount": crtdetail[i].sub_total,
            "tax": crtdetail[i].tax,
            "discount": crtdetail[i].discount,
            "base_amount": crtdetail[i].base_amount,
            "tax1_rate": crtdetail[i].tax1_rate,
            "tax2_rate": crtdetail[i].tax2_rate,
            "sub_total": adjAmountSubTotal(crtdetail[i].amount, crtdetail[i].discount, crtdetail[i].tax, crtdetail[i].quantity).toFixed(2),
          })
        }



        var fileData = {
          "member_id": memberId,
          "client_id": clientId,
          "reference_id": trid,
          "payment_mode": mode,
          "payment_note": notes,
          "data": cartTemp,
          'priceData': tablePrice,
        }
        const resp = await pay_at_desk(fileData);
        if (resp) {
          setLoder(false);
          Swal.fire({
            html: `
              <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
                SUCCESS
              </h4>
              <p class="text-center my-4 textcolor">
                <small class="textWhite">
                  ${resp.data.message}
                </small>
              </p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              if (JSON.parse(localStorage.getItem('st_isPassPurchaseFlow'))) {
                let getFinale = JSON.parse(localStorage.getItem('allsetData'));
                navigate('/client-search', { state: { back: 'pre', class_id: getFinale.class_id, schedule_id: getFinale.schedule_id, serchKey: JSON.parse(localStorage.getItem('st_searchedClients')) } });
              } else {
                navigate("/make-a-purchase");
              }
            }
          });
        }
        setLoder(false);

        //  this.classSpinner = true;
        //  const resp = await clover_pay_checkout_single();
        // alert("All Done");
        // console.log(fValue);
      }
      setLoder(false);
    }


  }
  return (
    <>
      <Form autoComplete="off" onSubmit={handleSubmit} >
        <Row className="my-4">
          {/* <Col></Col> */}
          <Col lg={12}>
            <div className="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
              <div className="px-3 py-3 text-left light-bg border-t-lr-6">
                <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0">
                  payment details
                </h5>
              </div>
              <div className="p-3 ">

                <Row>
                  <Col>
                    <SharedSelect
                      options={paymodeOP}
                      name={"mode"}
                      error={error.mode}
                      onChange={errorHandler}
                      lable={"Payment Mode"} />
                  </Col>
                  <Col>
                    <InputField
                      name={"trid"}
                      error={error.trid}
                      onChange={errorHandler}
                      lable={"Transaction ID"} type={"text"} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextArea
                      name={"notes"}
                      error={error.notes}
                      onChange={errorHandler}
                      lable={"Notes"} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SharedButton
                      title={"Save"}
                      variant={"primary"} type={"submit"} className={"w-100"} />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          {/* <Col></Col> */}
        </Row>
      </Form>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { errorAlert, successAlert } from "../../../../Static_Services/Alertmsg";
import { delete_website_otherSection_API, website_update_order_other_sectionAPI } from "../../../../Studio-Services/Studio_Services";
import Swal from "sweetalert2";
import logo from '../../../../Images/logo_white_web.png'

const UpdateOthersFiles = ({ data, setLoder, showFiles, setShowFiles, webinformaition }) => {
    const [files, setFiles] = useState([]);
    useEffect(() => { setFiles(data); }, [data]);

    const onDragStart = (e, index) => { e.dataTransfer.setData("index", index); };

    const onDrop = (e, index) => {
        const draggedIndex = parseInt(e.dataTransfer.getData("index"), 10);
        if (draggedIndex === index) return;

        const updatedFiles = [...files];
        const [draggedItem] = updatedFiles.splice(draggedIndex, 1);
        updatedFiles.splice(index, 0, draggedItem);
        setFiles(updatedFiles);
    };

    const onDragOver = (e) => { e.preventDefault(); };

    const handleDelete = (id) => {
        if (!id) { errorAlert("Id Required"); return false; };
        let payload = new FormData();
        payload.append('id', id);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoder(true);
                try {
                    const resp = await delete_website_otherSection_API(payload);
                    if (resp) {
                        Swal.fire({
                            html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
                            <h4 class="title text-uppercase text-center mt-4 textWhite">
                            SUCCESS
                            </h4>
                            <p class="text-center my-4 textcolor">
                            <small class="textWhite">
                            ${resp.data.message} </small></p> `,
                            showClass: {
                                popup: 'custom-dialog-container',
                            },
                            customClass: {
                                confirmButton: 'custom-button custom-button-size modalbtn',
                            },
                        });
                    }
                    setFiles(files.filter((file) => file.id !== id));
                } catch (error) {
                    console.log("@Error--------------", error);
                } finally {
                    setLoder(false);
                }
            }
        });
    };


    const orderUpdateHandler = async () => {
        if (files && files.length > 0) {
            setLoder(true);
            const preData = JSON.stringify(files);
            let payload = new FormData();
            payload.append('data', preData);
            try {
                const resp = await website_update_order_other_sectionAPI(payload);
                if (resp) {
                    successAlert(resp?.data?.message);
                }
            } catch (error) {
                console.log("@Error", error);
            } finally {
                webinformaition();
                setLoder(false);
            }
        } else {
            errorAlert("Invalid Data");
        }

    }


    const closeHandlerNew = () => {
        setShowFiles((pre) => ({ ...pre, show: false }));
        setShowFiles((pre) => ({ ...pre, data: "" }));
        webinformaition();
    }




    return (
        <Modal size="lg" show={showFiles?.show} onHide={() => { setShowFiles((pre) => ({ ...pre, show: false })); setShowFiles((pre) => ({ ...pre, data: "" })) }}>
            <Modal.Header closeButton>
                <Modal.Title style={{ 'width': '98%', display: 'flex' }}>
                    <span>Update Others Files </span>                
                     <span style={{ color: 'red', fontSize: "16px" ,marginLeft:'10px', marginTop:'10px'}}>Drag and Drop images to change order.</span>
               
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>#</th>
                            <th style={{ textAlign: "center" }}>File</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files && files.length > 0 && files.map((file, index) => (
                            <tr
                                key={file.id}
                                draggable
                                onDragStart={(e) => onDragStart(e, index)}
                                onDragOver={onDragOver}
                                onDrop={(e) => onDrop(e, index)}
                            >
                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                <td style={{ textAlign: "center", cursor: 'pointer' }}>
                                    {file.data_type === "image" ? (
                                        <img
                                            src={file?.data_file}
                                            alt="file preview"
                                            style={{ width: "180px", height: "150px" }}
                                        />
                                    ) : (
                                        <video
                                            controls
                                            style={{ width: "180px", height: "150px" }}
                                        >
                                            <source src={file?.data_file} type="video/mp4" />
                                            Your browser does not support video playback.
                                        </video>
                                    )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <button className="button_stye_123 ml-1 mr-3"
                                        style={{ backgroundColor: "red", color: "white", border: 'red' }}
                                        onClick={() => handleDelete(file.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                {/* <div className="w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>                  
                    <div> */}
                        <Button type='button' className="mr-2" variant="primary" onClick={() => orderUpdateHandler()}>
                            update
                        </Button>
                        <Button type='button' variant="danger" style={{ padding: '12px' }} onClick={() => closeHandlerNew()}>
                            Close
                        </Button>
                    {/* </div>
                </div> */}
            </Modal.Footer>
        </Modal>
    )
};

export default UpdateOthersFiles;

import https from '../client-staff-https';
import Swal from 'sweetalert2';
import logo from "../Images/logo_white_web.png";
import { errorAlert } from './Alertmsg';
import moment from 'moment';

const monthArray = [
  'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
];

export const favourite = async (data) => {
    try {
      const resp = await https.post("/webservices/api/favourite", data);
      if (resp.data.status == 1) {
        return resp;
      } else {
        errorAlert(resp.data.message)
        // Swal.fire({
        //   html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        //     <h4 class="title text-uppercase text-center mt-4 textWhite">
        //     Error !!!
        //   </h4>
        //   <p class="text-center my-4 textcolor">
        //   <small class="textWhite">
        //  ${} </small></p>
        //   `,
        //   showClass: {
        //     popup: 'custom-dialog-container',
        //   },
        //   customClass: {
        //     confirmButton: 'custom-button custom-button-size modalbtn',
        //   },
        // });
      }
    } catch (error) {
      alert(error);
    }
  }

  export const Staff_class_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/class_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const Staff_class_list_New_API = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/class_list_new", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_class_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/class_list_pankaj", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const Add_become_member_staff = async (data) => {
    try {
      const resp = await https.post("/webservices/api/add_become_member", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_instructor_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/instructor_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_workshop_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/workshop_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_workshop_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/workshop_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_my_book_services_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/my_book_services_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_services_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/services_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const Instructor_services_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/services_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const buy_now_services_cash = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/buy_now_services_cash", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const client_my_book_services_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/my_book_services_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_book_services = async (data) => {
    try {
      const resp = await https.post("/webservices/api/book_services", data);
      if (resp.data.status == 1) {
        return resp;
      } errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_cancel_policy = async () => {
    try {
      const resp = await https.get("/webservices/api/cancel_policy");
      if (resp.data.status == 1) {
        return resp;
      } errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const INST_book_services = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/book_services", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_class_details = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/class_details", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_my_classes_attandance = async (data) => {
    try {
      const resp = await https.post("/webservices/api/my_classes_attandance", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_my_attendance = async (data) => {
    try {
      const resp = await https.post("/webservices/api/my_attendance", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_my_purchase_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/my_purchase_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const ageCalculation = (dob) =>{
    const today = new Date();
    const currYear = today.getFullYear();
    const birthyear = dob.split('-');
    const age = currYear - birthyear[0];
    return age;
  }



  export const API_class_details = async (data) => {
    try {
      // const resp = await https.post("/webservices/api/class_details", data);
      const resp = await https.post("/webservices/api/class_details_test", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_send_appointment_mail= async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/send_appointment_mail", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_business_workshop_details = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/business_workshop_details", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_search_customer_for_workshop = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/search_customer_for_workshop", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_register_studio = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/register_studio", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }


  export const api_register_studio = async (data) => {
    try {
      const resp = await https.post("/webservices/api/register", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }


  export const api_verify_otp = async (data) => {
    try {
      const resp = await https.post("/webservices/api/verify_otp", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const api_resend_otp = async (data) => {
    try {
      const resp = await https.get("/webservices/api/resend_otp", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_weekdays_timeslot = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/get_weekdays_timeslot", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_client_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/client_list", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_user_profile = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/get_user_profile", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_shift_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/shift_list", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_instructor_availabilityn = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/instructor_availabilityn", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_business_workshop_details = async (data) => {
    try {
      const resp = await https.post("/webservices/api/business_workshop_details", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const API_workshop_appointment_cancel = async (data) => {
    try {
      const resp = await https.post("/webservices/api/workshop_appointment_cancel", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_user_dashboard = async (data) => {
    try {
      const resp = await https.post("/webservices/api/user_dashboard", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const API_forgot_password = async (data) => {
    try {
      const resp = await https.post("/webservices/api/forgot_password", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_instructor_details = async (data) => {
    try {
      const resp = await https.post("/webservices/api/instructor_details", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }
  
  export const staff_get_notification_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_notification_list", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_validate = async (data) => {
    try {
      const resp = await https.post("/webservices/api/validate", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_getUserQuestionnaire = async (data) => {
    try {
      const resp = await https.post("/webservices/api/getUserQuestionnaire", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_read_notification = async (data) => {
    try {
      const resp = await https.post("/webservices/api/read_notification", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_categories = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_categories", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_business = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_business", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_business_search = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_business", data);
      if (resp) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const staff_notification_on_off = async (data) => {
    try {
      const resp = await https.post("/webservices/api/notification_on_off", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_profile = async (data) => {
    try {
      const resp = await https.get("/webservices/api/get_profile", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  } 
  export const API_get_switch_user_profile = async (data) => {
    try {
      const resp = await https.get("/webservices/api/get_switch_user_profile", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  } 

  export const staff_get_skills = async () => {
    try {
      const resp = await https.get("/webservices/api/get_skills");
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  } 

  export const staff_all_products_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/all_products_list",data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  } 

  export const staff_profile_update = async (data) => {
    try {
      const resp = await https.post("/webservices/api/profile_update", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  } 

  export const staff_changepassword = async (data) => {
    try {
      const resp = await https.post("/webservices/api/changepassword", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  } 

  export const staff_get_countries = async (data) => {
    try {
      const resp = await https.get("/webservices/api/get_countries", data);
      if (resp.data.status == 1) {
        return resp;
      } 
    } catch (error) {
      alert(error);
    }
  }

  export const staff_passes_status_change = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/passes_status_change", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_passes_status_change = async (data) => {
    try {
      const resp = await https.post("/webservices/api/passes_status_change", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }
  

  export const staff_service_status_change = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/service_status_change", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_service_appointment_cancel = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/service_appointment_cancel", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_service_appointment_cancel = async (data) => {
    try {
      const resp = await https.post("/webservices/api/service_appointment_cancel", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_add_cart = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/add_cart", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_add_cart = async (data) => {
    try {
      const resp = await https.post("/webservices/api/add_cart", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const Instructor_clover_buy_now_services_single = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/clover_buy_now_services_single", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const Instructor_buy_now_workshop_cash = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/buy_now_workshop_cash", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_clover_buy_now_services_single = async (data) => {
    try {
      const resp = await https.post("/webservices/api/clover_buy_now_services_single", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_clover_buy_now_workshop_single = async (data) => {
    try {
      const resp = await https.post("/webservices/api/clover_buy_now_workshop_single", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const Instructor_clover_buy_now_workshop_single = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/clover_buy_now_workshop_single", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_cart_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/cart_list", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_cart_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/cart_list", data);
      if (resp.data.status == 1) {
        localStorage.setItem('w_CartValues',JSON.stringify(resp.data.data.total_item));
        return resp;
      }else{
        //  errorAlert(resp.data.message); 
        localStorage.removeItem('w_CartValues');
      };
    } catch (error) {
      alert(error);
    }
  }
  export const staff_add_client = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/add_client", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }


  export const staff_services_details = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/services_details", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_services_details = async (data) => {
    try {
      const resp = await https.post("/webservices/api/services_details", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  
  export const staff_service_scheduling_time_slot = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/service_scheduling_time_slot", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const API_service_scheduling_time_slot = async (data) => {
    try {
      const resp = await https.post("/webservices/api/service_scheduling_time_slot", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const API_getInstructorShiftDate = async (data) => {
    try {
      const resp = await https.post("/webservices/api/getInstructorShiftDate", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }


  export const staff_search_customer_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/search_customer_list", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }


  export const Web_search_customer_list = async (data) => {
    try {
      const resp = await https.post("/web/studio/search_customer_list", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const staff_search_customer_details = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/search_customer_details", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }





  export const Web_search_customer_details = async (data) => {
    try {
      const resp = await https.post("/web/studio/search_customer_details", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const staff_new_client_signup = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/new_client_signup", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_pay_at_desk = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/pay_at_desk", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_pay_at_desk = async (data) => {
    try {
      const resp = await https.post("/webservices/api/pay_at_desk", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_remove_cart = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/remove_cart", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_update_cart = async (data) => {
    try {
      const resp = await https.post("/webservices/api/update_cart", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_clover_buy_now_single = async (data) => {
    try {
      const resp = await https.post("/webservices/api/clover_buy_now_single", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_clover_pay_checkout_single = async (data) => {
    try {
      const resp = await https.post("/webservices/api/clover_pay_checkout_single", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_cardGet = async (data) => {
    try {
      const resp = await https.post("/webservices/api/cardGet", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const App_add_free_pass_API = async (data) => {
    try {
      const resp = await https.post("/webservices/api/App_add_free_pass", data);
      if (resp.data.status == 1) {
        return resp;
      }else{     
        errorAlert(resp.data.message);
      }
    } catch (error) {
      alert(error);
    }
  }

  export const API_cardDelete = async (data) => {
    try {
      const resp = await https.post("/webservices/api/cardDelete", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_clover_card_save = async (data) => {
    try {
      const resp = await https.post("/webservices/api/clover_card_save", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_remove_cart = async (data) => {
    try {
      const resp = await https.post("/webservices/api/remove_cart", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }


  export const API_term_and_condition_recurring_pass = async () => {
    try {
      const resp = await https.get("/Welcome/web_content/term-and-condition-recurring-pass");
      if (resp) {
        return resp;
      }else {
        if(resp && resp.data && resp.data.message){
          errorAlert(resp.data.message)};
        }
    } catch (error) {
      // errorAlert(error);
    }
  }

  export const staff_shift_cancel_request = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/shift_cancel_request", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_search_customer_for_services = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/search_customer_for_services", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const staff_passes_details = async (data) => {
    try {
      const resp = await https.post("/webservices/api/passes_details", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_my_class_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/my_class_list", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const API_my_book_workshop_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/my_book_workshop_list", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_products_details = async (data) => {
    try {
      const resp = await https.post("/webservices/api/products_details", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_relations = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_relations", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_add_member = async (data) => {
    try {
      const resp = await https.post("/webservices/api/add_member", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const API_get_business_detail = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_business_detail", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  
  export const mobile_free_passes_list_API = async (data) => {
    try {
      const resp = await https.post("/webservices/api/mobile_free_passes_list", data);
      if (resp.data.status == 1) {
        return resp;
      }
      // else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }


  export const Instructor_get_business_detail = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/get_business_detail", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_transaction_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/transaction_list", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const submitQuestionnaire = async (data) => {
    try {
      const resp = await https.post("/webservices/api/submitQuestionnaire", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }
  
  export const staff_edit_member = async (data) => {
    try {
      const resp = await https.post("/webservices/api/edit_member", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_remove_member = async (data) => {
    try {
      const resp = await https.post("/webservices/api/remove_member", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_member_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_member_list", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const staff_get_member_new_list = async (data) => {
    try {
      const resp = await https.post("/webservices/api/get_member_list_new", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const INST_get_member_list = async (data) => {
    try {
      const resp = await https.post("/webservices/instructor/get_member_list", data);
      if (resp.data.status == 1) {
        return resp;
      }
    } catch (error) {
      alert(error);
    }
  }

  export const staff_favouritelist = async (data) => {
    try {
      const resp = await https.post("/webservices/api/favouritelist", data);
      if (resp.data.status == 1) {
        return resp;
      }else errorAlert(resp.data.message);
    } catch (error) {
      alert(error);
    }
  }

  export const ExpiryCheck = (expiry)=>{
    var today = new Date();
      var currYear = today.getFullYear();
      var month =  today.getMonth() + 1;
      var newmonth;
      if(month < 10){
        newmonth = '0'+ month;
      }
      var expired = expiry.split('/');

      if((expired[0] < newmonth && expired[1] <=  currYear )||  expired[1] <  currYear) {
        errorAlert('Expiry Month & Year is not valid')
        return false;
      } else{
        return expired;
      }
  }


  export const timeConvert = (time) =>{
    const update = moment.unix(time).format('hh mm A');
    return update;
  }

  export const toTimestamp = (strDate) => {
    const datum = moment(strDate);
    return datum.unix();
  }

  export const dateConvert  = (Dates, format = 1) => {
    var event_date_start_utc1;
    event_date_start_utc1 = new Date(Dates * 1000);
    var date = event_date_start_utc1.getDate();
    if (date < 10) {
      date = '0' + date;
    }
    var month = event_date_start_utc1.getMonth();
    var year = event_date_start_utc1.getFullYear();
    if (format === 1) {
      var newdate = monthArray[month] + ' ' + date + ' ' + year;
    } else {
      const mon = month + 1;
      var newdate = ((mon < 10) ? ('0' + mon) : mon) + '/' + date + '/' + year;
    }

    return newdate;
  }

  export const toDate = (date) => {
    const monthArray = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const day = date.toString().slice(4, 15);
    const day1 = day.split(' ');
    const newMonth = monthArray.findIndex(rank => rank === day1[0]) + 1;
    const formattedMonth = newMonth < 10 ? '0' + newMonth : newMonth;
    const dob = day1[2] + "-" + formattedMonth + "-" + day1[1];
  
    return dob;
  }
    export const convertDate = ({ Dates, format=1 }) => {
      const monthArray = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      const event_date_start_utc1 = new Date(Dates * 1000);
      const date = event_date_start_utc1.getDate();
      const month = event_date_start_utc1.getMonth();
      const year = event_date_start_utc1.getFullYear();
  
      if (format === 1) {
        return `${monthArray[month]} ${date} ${year}`;
      } else {
        const mon = month + 1;
        return `${mon < 10 ? '0' + mon : mon}/${date}/${year}`;
      }
    };

    export const convertTime = ({times} ) => {
      const event_date_start_utc = new Date(times * 1000);
      const hours = event_date_start_utc.getHours() > 12 ? event_date_start_utc.getHours() - 12 : event_date_start_utc.getHours();
      const am_pm = event_date_start_utc.getHours() >= 12 ? "PM" : "AM";
      const hoursEnd = hours < 10 ? "0" + hours : hours;
      const minutes = event_date_start_utc.getMinutes() < 10 ? "0" + event_date_start_utc.getMinutes() : event_date_start_utc.getMinutes();
      const seconds = event_date_start_utc.getSeconds() < 10 ? "0" + event_date_start_utc.getSeconds() : event_date_start_utc.getSeconds();
      const time = hoursEnd + ":" + minutes + " " + am_pm;
      return time;
    };
import React, { useEffect, useState } from 'react'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { Footer } from '../../../../../public/containers/footer/Footer'
import Sidebar from '../../comman_page/Sidebar'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { AddClientform } from '../../../../../sharedComponents/AddClientform'
import { client_list } from '../../../../../Studio-Services/Studio_Services'
import { Link } from 'react-router-dom'


export const Addclient = () => {
  const [loder,setLoder] = useState(false);
  const [table,setTable] = useState([]);
  const [tableList,setTableList] = useState([]);

  const get_clinet_List = async () => {
      const data = { pageid: 1 };
      const resp = await client_list(data);
      if (resp) {
        const array = [];
        (resp.data).forEach((element, index) => {
          array.push({
            key: index,
            fname: element.first_name,
            lname: element.last_name,
            email: element.email,
            phone: element.mobile,
            action: (
              <Link
                to="/edit-client"
                style={{ textDecoration: "none", color: "black" }}
                state={{ id: element.id }}
              >
                Edit
              </Link>
            ),
          });
        });
        setTable(array);
        setTableList(array);
      }
    };

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient">
                    <Container className="pt-5">
                      <h4 className='mt-5 pt-4 pb-3'>Add Client</h4>
                      <div className='AddClientForm AddClientContent'>
                      <AddClientform get_clinet_List={get_clinet_List} setLoder={setLoder} />
                      </div>
                    </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>

    </>
  )
}
